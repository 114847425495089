<template>
  <div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { EventBus } from '@/plugins/eventbus.js';
import accountService from "@/services/accountService";
import tenants from '@/data/tenants.config'
import i18n from '@/i18n'
// https://docs.microsoft.com/en-us/javascript/api/overview/msteams-client?view=msteams-client-js-latest
//import * as microsoftTeams from "@microsoft/teams-js";
const tenant = tenants.current();
//const microsoftTeams = window.microsoftTeams;

export default {
  components: {
  },
  props: {
    microsoftTeams: Object,
  },
  data: () => ({
  }),
  async mounted() {
    await this.tryCompleteFlow();
    EventBus.$on('login-state-change', async user => {
      console.log('teams auth state', this.user);
      await this.tryCompleteFlow();
    });
  },
  methods: {
    async tryCompleteFlow() {
      
      console.log('teams available in auth end', this.microsoftTeams, window.location.hash);
      console.log('AUTH result', this.user);
      if (this.user) {

        var tokenResponse = await accountService.createToken();
        //console.log('AUTH token', tokenResponse.data);

        this.microsoftTeams.authentication.notifySuccess(tokenResponse.data);
      }
      return;

      if (this.microsoftTeams) {
        let hashParams = this.getHashParameters();
        if (hashParams["error"]) {
          // Authentication/authorization failed
          console.log('auth error', JSON.stringify(hashParams));
          localStorage.setItem("auth.error", JSON.stringify(hashParams));
          microsoftTeams.authentication.notifyFailure(hashParams["error"]);
        } 
        else if (hashParams["code"]) {
          const auth = getAuth();
          let expectedState = localStorage.getItem("auth.state");
          if (expectedState !== hashParams["state"]) {
            // State does not match, report error
            console.log('auth state doesnt match', expectedState, JSON.stringify(hashParams));
            localStorage.setItem("auth.error", JSON.stringify(hashParams));
            microsoftTeams.authentication.notifyFailure("StateDoesNotMatch");
          } else {
            // Only call notifySuccess or notifyFailure if this page is in the authentication popup
            if (window.opener) {
              var response = await signInWithCustomToken(auth, token);
              if (response && response.user) {
                this.$store.dispatch("setUser", response.user);
                console.log('auth callback with user ', response.user);
                this.microsoftTeams.authentication.notifySuccess();
              } 
              else {
                console.log('auth callback with error ', response);
                this.microsoftTeams.authentication.notifyFailure(authContext.getLoginError());
              }
            }
          }
        }      
        else {
          // Authentication/authorization failed
          console.log('auth error', JSON.stringify(hashParams));
          localStorage.setItem("auth.error", JSON.stringify(hashParams));
          microsoftTeams.authentication.notifyFailure(hashParams["error"]);
        } 
      }
    },

    // Parse hash parameters into key-value pairs
    getHashParameters() {
        let hashParams = {};
        window.location.hash.substr(1).split("&").forEach(function (item) {
            let s = item.split("="),
                k = s[0],
                v = s[1] && decodeURIComponent(s[1]);
            hashParams[k] = v;
        });
        return hashParams;
    },
  },
  computed: {

    ...mapGetters({
      user: "user"
    }), 
  },
  watch: {
    microsoftTeams() {
      this.tryCompleteFlow();
    }
  }
};
</script>
<style lang="scss" scoped>
  

</style>

